import { hasRole } from '/@tools/general-utils';
import { Role } from '/@shared/types/ids';
import { NavGroup } from '/@shared/app/app.types';

const navGroups: NavGroup[] = [
  {
    title: 'APP-ADMIN',
    items: [
      {
        title: 'Excel-import',
        icon: 'f56f',
        to: () => '/admin/import',
        check: ({ user }) => hasRole(user, [Role.AppOwner]),
      },
      {
        title: 'Excel-import bulk',
        icon: 'f56f',
        to: () => '/admin/import-bulk',
        check: ({ user }) => hasRole(user, [Role.AppOwner]),
      },
      {
        title: 'Publikum',
        icon: 'f7a2',
        external: true,
        to: ({ user }) => `/public/${user.tenant.guid}/map`,
        check: ({ user }) => hasRole(user, [Role.AppOwner]),
      },
      {
        title: 'Sjekklister',
        icon: 'f733',
        external: true,
        to: ({ user }) => `/public/${user.tenant.guid}/request`,
        check: ({ user }) => hasRole(user, [Role.AppOwner]),
      },
    ],
  },
];

export default navGroups;
